.video-main{
    width: 100%;
    height: 100%;
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: xx-large;
    font-weight: 900;
}
.video-text{
    font-size: 4vw;
    font-family: cursive;
    text-shadow: 7px 6px coral;
}
.qrImageOnVideo{
    width:18%;
    opacity:.6
}